import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// import {onCLS, onINP, onLCP} from 'web-vitals';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

let theme = createTheme({
  palette: { 
    primary: {
      main: 'rgba(139,133,149,1)',
      },
    secondary: {
      main: '#eee',
      },
    error: {  
      main: '#f44336',
    },
    text: {
      primary: '#333',
      secondary: '#fff',
    }
  },

});

theme = responsiveFontSizes(theme);

root.render(
  <React.StrictMode>
    <ThemeProvider  theme={theme}>
       <App />
    </ThemeProvider>
  </React.StrictMode>
);

// Learn more about analytics: https://bit.ly/CRA-vitals
// function sendToAnalytics(metric: any) {
//   const body = JSON.stringify(metric);
//   console.log(body);
// }

// onCLS(sendToAnalytics);
// onINP(sendToAnalytics);
// onLCP(sendToAnalytics);
